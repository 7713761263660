import React from 'react';
import { IonCard, IonButton, IonCardSubtitle, IonCardHeader, IonCardTitle, IonCardContent, IonItem, IonInput, IonLabel, IonDatetime, IonSelect, IonSelectOption, IonActionSheet } from '@ionic/react';
import QrReader from 'react-qr-reader';
import Status from '../components/Status';
import ShareButton from './ShareButton';
import axios from "axios";
import { trash, close } from 'ionicons/icons';

class QRreader extends React.Component<{store: any}> {

    // Constructor
    constructor(props: any) {
        super(props);
        this.handleScan = this.handleScan.bind(this);
        this.handleError = this.handleError.bind(this);
        this.reset = this.reset.bind(this);
        this.getTagInfos = this.getTagInfos.bind(this);
        this.check_step = this.check_step.bind(this);
        this.get_time = this.get_time.bind(this);
        this.convert_time = this.convert_time.bind(this);
        this.res_rec = this.res_rec.bind(this);
        this.res_rec_b = this.res_rec_b.bind(this);
        this.get_pos = this.get_pos.bind(this);
        this.setForm = this.setForm.bind(this);
        this.cancel_scan = this.cancel_scan.bind(this);
        this.update_render = this.update_render.bind(this);
    }

    // Restore session
    componentDidMount() {
        const saved_h1 = localStorage.getItem('h1') ? localStorage.getItem('h1') : '';
        const saved_h2 = localStorage.getItem('h2') ? localStorage.getItem('h2') : '';
        const saved_h3 = localStorage.getItem('h3') ? localStorage.getItem('h3') : '';
        const saved_h4 = localStorage.getItem('h4') ? localStorage.getItem('h4') : '';
        const saved_step = localStorage.getItem('step') ? localStorage.getItem('step') : '';
        const p = localStorage.getItem('temp_p') ? localStorage.getItem('temp_p') : '';
        const h = localStorage.getItem('temp_h') ? localStorage.getItem('temp_h') : '';
        const saved_form = localStorage.getItem('form') ? localStorage.getItem('form') : '';

        this.setState({
            h1: saved_h1,
            h2: saved_h2,
            h3: saved_h3,
            h4: saved_h4,
            step: saved_step,
            form: saved_form
        })

        //console.log("H: " + h + ", P: " + p + ", Step: " + saved_step);

        let old_state = '';
        switch(saved_step) {
            case "s":
                old_state = '';
            break;
            case "i":
                old_state = 's';
            break;
            case "ii":
                old_state = 'i';
            break;
            case "f":
                old_state = 'ii';
            break;
        }
        if(p && h) {
            this.check_step(p, h, old_state);
        }
    }

    update_render(step: any) {
        switch(step) {
            case "resc":
                this.setState({
                    com: 'Résultat enregistré',
                    com_un: 'Félicitations pour ta participation et au plaisir de te rencontrer à Anzère le 17 juillet 2021 pour la prochaine édition du Tour des Alpages',
                    form: 's',
                    wait: false
                })
                localStorage.setItem("form", 's');
            break;
        }
    }

    // State
    init_state = {
        step: '',
        hash: '',
        h1: '',
        h2: '',
        h3: '',
        h4: '',
        time: '',
        uuid: '',
        time_start: '',
        form: '',
        info_date: '',
        info_gender: '',
        info_race: '',
        info_cat: 'Cat',
        info_name: '',
        info_firstname: '',
        info_npa: '',
        info_email: '',
        pos: 0,
        cancel: false,
        wait: false,
        newload: true,
        step_render: '',
        com: 'Anzère place du village',
        com_un: 'Pour débuter, scanne le QR code qui se trouve au départ du parcours.',
        btn: '[Scanner] Départ',
        scan: false
    }
    
    state = this.init_state;

    // Reset
    cancel_scan() {
        this.setState(this.init_state);
        localStorage.clear();
    }

    reset() {
        this.setState({
            hash: '',
            uuid: '',
            com: '',
            com_un: ''
        })
    }

    handleScan(data: any) {
        const base_url = "https://tda.valais.cloud?c"
        //console.log('QR Scanned')
        if (data) {
            if(data.length > base_url.length)
            {
                var url = data.substring(0,base_url.length)
                if(url === base_url)
                {
                    this.getTagInfos(data.substring(base_url.length+1, data.length))
                    //console.log("New result: " + this.state.step);
                } else {
                    this.setState({
                        name: 'Unvalid URL'
                    })
                }
            }
            else {
                this.setState({
                    name: 'Unvalid URL'
                })
            }
        }
    }

    handleError(err: any) {
        //console.log('QR Error')
        console.error(err)
    }

    setForm(val: any)
    {
        this.setState({
            form: "c"
        });
        localStorage.setItem("form", 'c');
    }

    set_res(response: any) {
        let h = response.data.h
        let p = response.data.p
        localStorage.setItem("temp_h", h);
        localStorage.setItem("temp_p", p);
        //console.log("TagInfo result: " + h + ", " + p);
        this.check_step(p, h, localStorage.getItem('step'));
    }

    set_res_b(response: any) {
        let h = response.data.h

        //console.log("Hash result: " + h);
        this.setState({
            hash: h,
            wait: true
        });
        localStorage.setItem("form", 's');
        this.get_pos(h);
    }

    set_res_c(response: any) {
        this.setState({
            form: "s"
        });
        localStorage.setItem("form", 's');
        this.setState({
            com: 'Résultat enregistré',
            com_un: 'Félicitations pour ta participation et au plaisir de te rencontrer à Anzère le 17 juillet 2021 pour la prochaine édition du Tour des Alpages',
            form: 's',
            wait: false
        });
        localStorage.setItem("form", 's');
    }

    get_tm(response: any) {
        let t = response.data.t

        //console.log("Time result: " + t);
        this.setState({
            time: t,
            com_un: 'Temps: ' + this.convert_time(t)
        });
        localStorage.setItem("temps", this.convert_time(t));
    }

    convert_time(time: any) {
        const h = (time-time%3600)/3600
        time = time - h*3600
        const mn = (time-(time%60))/60
        time = time - mn*60
        const sec = time
        return (h + " h " + mn + " mn " + sec + " s")
    }

    check_step(p: string, h: string, current_step: any) {
        //console.log(">> Tag Info result: " + h + ", " + p + ", " + current_step);
        //console.log("Step for check: " + current_step);
        if(current_step === '' || current_step === null) {
            if(p === 's') {
                var hours = new Date().getHours();
                var min = new Date().getMinutes();
                var sec = new Date().getSeconds();
                this.setState({
                    //hash: h,
                    h1: h,
                    step: p,
                    com: 'La course a débuté!',
                    com_un: 'Prochaine étape: le QR code au point culminant.',
                    time_start: 'Heure de départ: ' + hours + ':' + min + ':' + sec,
                    btn: '[Scanner] Arrivée au point culminant'
                });
                //console.log("Set h1: " + h);
                localStorage.setItem('h1', h);
                localStorage.setItem('step', p);
                return null;
            } else {
                this.setState({
                    com: 'Commence par le début',
                    com_un: 'Le premier QR code se trouve sur la place du village d\'Anzère.'
                });
                return null;
            }
        }

        if(current_step === 's') {
            if(p === 'i') {
                this.setState({
                    //hash: h,
                    h2: h,
                    step: p,
                    com: 'Scanné, continue la course!',
                    com_un: 'Prochaine étape: le QR code des Rousses.',
                    btn: '[Scanner] Arrivée aux Rousses'
                });
                localStorage.setItem('step', p);
                localStorage.setItem('h2', h);
                return null;
            } else {
                this.setState({
                    com: 'C\'est le moment de scanner le QR code du poin culminant',
                    com_un: 'Le tag se trouve sur le parcours au point culminant.'
                });
                return null;
            }
        }

        if(current_step === 'i') {
            if(p === 'ii') {
                this.setState({
                    //hash: h,
                    h3: h,
                    step: p,
                    com: 'Scanné, continue la course!',
                    com_un: 'Prochaine étape: le QR code de l\'arrivée.',
                    btn: '[Scanner] Arrivée'
                });
                localStorage.setItem('h3', h);
                localStorage.setItem('step', p);
                return null;
            } else {
                this.setState({
                    com: 'C\'est le moment de scanner le QR code des Rousses',
                    com_un: 'Le tag se trouve sur le parcours aux Rousses.'
                });
                return null;
            }
        }

        if(current_step === 'ii') {
            if(p === 'f') {
                this.setState({
                    //hash: h,
                    h4: h,
                    step: p,
                });
                localStorage.setItem('h4', h);
                localStorage.setItem('step', p);
                localStorage.setItem("form", 'b');
                this.get_time();
                return null;
            } else {
                this.setState({
                    com: 'C\'est le moment de terminer la course',
                    com_un: 'Le dernier QR code se trouve sur la place du village d\'Anzère.'
                });
                return null;
            }
        }
    }

    set_get_pos(response: any) {
        let pos_local = response.data.position;
        //console.log("Pos: " + pos_local);
        this.setState({
            pos: pos_local,
            form: 'c',
            wait: false
        });
        localStorage.setItem("pos", pos_local);
        localStorage.setItem("form", 'c');
        const txt  = (localStorage.getItem("pos") === '1') ? 'er' : 'ème';
        this.setState({
            com: localStorage.getItem("temps"),
            com_un: 'Pour le moment, tu es ' + localStorage.getItem("pos") + txt + ' au classement général pour la course virtuelle 2021.',
            btn: ''
        });
    }

    set_error(error: any) {
        //console.log("TagInfo error: " + error);
    }

    set_then() {

    }

    datab_savec(data: any) {
        //console.log("Data b savec: " + data)
    }

    res_rec_b() {
        const url = 'https://dbrace.valais.cloud/insc/b/' + this.state.info_gender + '/' + this.state.info_date.substring(0, 4) + '/' + this.state.info_race + '/' + localStorage.getItem('h1') + '/' + localStorage.getItem('h2') + '/' + localStorage.getItem('h3') + '/' + localStorage.getItem('h4') + '/5255b44b1ac4f1df35f03c6f09ab46f2';
        let res = ''
        axios.get(url, )
            .then((response) => this.set_res_b(response))
            .catch((error) => this.set_error(error))
            .then(() => this.set_then());
            //console.log(url);
        localStorage.setItem("form", 's');
        this.setState({
            com: "Enregistrement..."
        })
        //console.log(url);
    }
    
    get_pos(h: any) {
        const url = 'https://dbrace.valais.cloud/res/user/' + h + '/5255b44b1ac4f1df35f03c6f09ab46f2';
        let res = ''
        axios.get(url, )
            .then((response) => this.set_get_pos(response))
            .catch((error) => this.set_error(error))
            .then(() => this.set_then());
        //console.log(url);
    }

    res_rec() {
        const url = 'https://dbrace.valais.cloud/insc/c/' + this.state.info_firstname + '/' + this.state.info_name + '/' + this.state.info_npa + '/' + this.state.info_email + '/' + this.state.hash + '/5255b44b1ac4f1df35f03c6f09ab46f2';
        let res = ''
        axios.get(url, )
            .then((response) => this.set_res_c(response))
            .catch((error) => this.set_error(error))
            .then(() => this.set_then());
    }

    get_time() {
        const url = 'https://dbrace.valais.cloud/res/time/' + localStorage.getItem('h1') + '/' + localStorage.getItem('h2') + '/' + localStorage.getItem('h3') + '/' + localStorage.getItem('h4') + '/5255b44b1ac4f1df35f03c6f09ab46f2';
        let res = ''
        axios.get(url, )
            .then((response) => this.get_tm(response))
            .catch((error) => this.set_error(error))
            .then(() => this.set_then());
    }

    getTagInfos(data: any) {
        //console.log("Data: " + data);
        const url = 'https://dbrace.valais.cloud/tag/start/' + data + '/5255b44b1ac4f1df35f03c6f09ab46f2';
        let res = ''
        axios.get(url, )
            .then((response) => this.set_res(response))
            .catch((error) => this.set_error(error))
            .then(() => this.set_then());
    };

    render() {
        let html
        let reset_button
        let cancel_button
        let form
        let form_b
        let form_c
        let form_s
        let showQR = false;
        if(this.state.com === '') {
            showQR = true;
        }
        if(localStorage.getItem("form") === 'b') {
            showQR = false;
        }
        
        if (showQR) {
            html = <QrReader delay={300} onError={this.handleError} onScan={this.handleScan} style={{ width: '100%' }} />
        } else {
            //html = <ItemCard title={this.state.com} subtitle={this.state.com_un} content={this.state.time_start} />
            reset_button = <IonButton onClick={this.reset} expand="block">{this.state.btn}</IonButton>
            cancel_button = <IonButton shape="round" size="small" expand="block" onClick={() => this.setState({cancel: true})} color="danger">Recommencer</IonButton>
            if(localStorage.getItem('form') === "cw" || localStorage.getItem('form') === "c" || localStorage.getItem('form') === "s") {
                reset_button = ''
            }

            if(localStorage.getItem('form') !== 'b' && !this.state.wait) {
                html = <IonCard>
                        <IonCardHeader>
                            <IonCardSubtitle>{this.state.time_start}</IonCardSubtitle>
                            <IonCardTitle>{this.state.com}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>{this.state.com_un}</IonCardContent>
                        {reset_button}
                        {cancel_button}
                    </IonCard>
            }
        }
        
        if(localStorage.getItem('form') === "b") {
            let btn = <></>;
            if(this.state.info_gender !== '' && this.state.info_date !== '' && this.state.info_race !== '') {
                btn = <IonButton onClick={this.res_rec_b} expand="full">Afficher mon résultat</IonButton>
            }
            form_b = <IonCard><IonCardHeader><IonCardTitle>Félicitations!</IonCardTitle><IonCardSubtitle>Entre tes informations pour le calcul de la catégorie</IonCardSubtitle></IonCardHeader>
                <IonItem>
                    <IonLabel>Genre</IonLabel>
                        <IonSelect value={this.state.info_gender} placeholder="Femme/Homme" onIonChange={e => this.setState({info_gender: e.detail.value})}>
                        <IonSelectOption value="femme">Femme</IonSelectOption>
                        <IonSelectOption value="homme">Homme</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Année de naissance</IonLabel>
                        <IonDatetime
                            placeholder="Année de naissance" displayFormat="YYYY" min="1900" max="2020"
                            value={this.state.info_date} onIonChange={e => this.setState({info_date: e.detail.value})}>
                        </IonDatetime>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Course</IonLabel>
                        <IonSelect value={this.state.info_race} placeholder="Coureur/Marcheur" onIonChange={e => this.setState({info_race: e.detail.value})}>
                            <IonSelectOption value="coureur">Coureur</IonSelectOption>
                            <IonSelectOption value="marcheur">Marcheur</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    {btn}
                    {cancel_button}
                </IonCard>
        } else {
            form_b = <></>
        }

        if(localStorage.getItem('form') === "cw")
        {
            form_c = <IonButton onClick={this.setForm} expand="full">Enregistrer mon résultat</IonButton>
        }
        
        if(localStorage.getItem('form') === "c") {
            let btn = <></>;
            if(this.state.info_name !== '' && this.state.info_firstname !== '' && this.state.info_npa !== '' && this.state.info_email !== '') {
                btn = <IonButton onClick={this.res_rec} expand="full">Enregistrer mon nom</IonButton>
            }
            form_c = <IonCard><IonCardHeader><IonCardTitle>Informations</IonCardTitle></IonCardHeader>
                <IonItem>
                    <IonInput autocapitalize="characters" value={this.state.info_name} placeholder="Nom" onIonChange={e => this.setState({info_name: e.detail.value})}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput autocapitalize="characters" value={this.state.info_firstname} placeholder="Prénom" onIonChange={e => this.setState({info_firstname: e.detail.value})}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput value={this.state.info_npa} placeholder="NPA" onIonChange={e => this.setState({info_npa: e.detail.value})}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput value={this.state.info_email} placeholder="E-Mail" inputmode="email" onIonChange={e => this.setState({info_email: e.detail.value})}></IonInput>
                </IonItem>
                {btn}
            </IonCard>
        }

        if(localStorage.getItem('form') === "s") {
            form_s = <ShareButton
              config={{
                params: {
                  title: '#challangeTdA2021',
                  text: 'J\'ai terminé le Tour des Alpages à Anzère en ' + localStorage.getItem("temps") + ".",
                  url: 'https://tourdesalpages.ch/course-virtuelle',
                },
                onShareSuccess: () => console.log('Success'),
                onShareError: (error: Error) => console.log('error', error),
              }}
            />
        }

        //console.log("Form: " + localStorage.getItem('form'))

        let status = '0';
        if (this.state.h1 !== '')
            status = '1'
        if (this.state.h2 !== '')
            status = '2'
        if (this.state.h3 !== '')
            status = '3'
        if (this.state.h4 !== '')
            status = '4'
        if (localStorage.getItem('form') !== "" && localStorage.getItem('form') !== null)
            status = 'd'
        
        let tm = <></>
        if (localStorage.getItem("temps") !== null && false)
            tm = <IonCard>
                <IonCardHeader>
                    <IonCardSubtitle>Résultat</IonCardSubtitle>
                    <IonCardTitle>{localStorage.getItem("temps")}</IonCardTitle>
                </IonCardHeader>
                    {form_s}
                <IonCardContent>
                </IonCardContent>
            </IonCard>
        
        return (
            <>
                {html}
                {form}
                {form_b}
                {form_c}
                {tm}
                <Status status={status} />
                <IonActionSheet
                    isOpen={this.state.cancel}
                    onDidDismiss={() => this.setState({cancel: false})}
                    cssClass='my-custom-class'
                    buttons={[{
                    text: 'Recommencer',
                    role: 'destructive',
                    icon: trash,
                    handler: () => {
                        this.cancel_scan();
                        //console.log('Delete clicked');
                    }
                    }, {
                    text: 'Annuler',
                    icon: close,
                    role: 'cancel',
                    handler: () => {
                        //console.log('Cancel clicked');
                    }
                    }]}
                >
                </IonActionSheet>
            </>
        )
    }
}

export default QRreader;