import React, { useState } from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonToast } from '@ionic/react';
import { Maps } from '../components/Maps';
import './Tab3.css';

const Tab3: React.FC = () => {
  const [showToast1, setShowToast1] = useState(false);
  const [toast1Txt, setToast1Txt] = useState('');

  const mapStyles = {        
    height: "100vh",
    width: "100%"};
  
  const defaultCenter = {
    lat: 46.304978, lng: 7.417968
  };
  
  const coor = [
    {
      label: "Départ et arrivée",
      name: "Départ/Arrivée",
      location: { 
        lat: 46.297471, 
        lng: 7.399788 
      },
    },
    {
      label: "Le point culminant",
      name: "PointCulminant",
      location: { 
        lat: 46.318467321446,
        lng: 7.4044913004216
      },
    },
    {
      label: "Les Rousses",
      name: "Rousses",
      location: { 
        lat: 46.332514606147,
        lng: 7.4369619515625
      },
    }
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Carte</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Carte</IonTitle>
          </IonToolbar>
        </IonHeader>
          <Maps toastTxt={setToast1Txt} toast={setShowToast1} mapStyles={mapStyles} defaultCenter={defaultCenter} coor={coor} />
      </IonContent>
      <IonToast
        isOpen={showToast1}
        onDidDismiss={() => setShowToast1(false)}
        message={toast1Txt}
        duration={5000}
      />
    </IonPage>
  );
};

export default Tab3;
