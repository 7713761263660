import React from 'react';
import { IonList, IonItem, IonLabel, IonIcon, IonTitle, IonContent } from '@ionic/react';
import { camera, walk, trophy } from 'ionicons/icons';
import './InfoContainer.css';

interface ContainerProps {
  name: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name }) => {
  return (
    <IonContent>
        <IonTitle>
          Marche à suivre
        </IonTitle>
        <IonList>
          <IonItem>
            <IonIcon icon={camera} slot="start" />
            <IonLabel>
              <h2>1. Scanne le QR code de départ</h2>
              <p>Page "Scanner" depuis le menu</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={walk} slot="start" />
            <IonLabel>
              <h2>2. Rends-toi au point culminant (Duez)</h2>
              <p>!! En suivant le parcours du TdA !!</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={camera} slot="start" />
            <IonLabel>
              <h2>3. À Duez scanne le QR code</h2>
              <p>Page "Scanner" depuis le menu</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={walk} slot="start" />
            <IonLabel>
              <h2>4. Rends-toi aux Rousses</h2>
              <p>!! En suivant le parcours du TdA !!</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={camera} slot="start" />
            <IonLabel>
              <h2>5. Aux Rousses scanne le QR code</h2>
              <p>Page "Scanner" depuis le menu</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={walk} slot="start" />
            <IonLabel>
              <h2>6. Reprends le parcours</h2>
              <p>Direction de la place du village d'Anzère</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={camera} slot="start" />
            <IonLabel>
              <h2>7. Scanne le QR code d'arrivée</h2>
              <p>Page "Scanner" depuis le menu</p>
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonIcon icon={trophy} slot="start" />
            <IonLabel>
              <h2>8. Enregistre ton adresse e-mail</h2>
              <p>(Optionnel) Pour participer au tirage au sort</p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
  );
};

export default ExploreContainer;
