import React from 'react';
import webShare, { WebShareInterface } from 'react-web-share-api';

export interface OwnProps {
  style: object;
}

const ShareButton: React.StatelessComponent<WebShareInterface & OwnProps> = ({ share, isSupported, style,}) => isSupported
  ? <button onClick={share} style={style}>Partager mon résultat</button>
  : <></>;

export default webShare<OwnProps>()(ShareButton);