import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import InfoContainer from '../components/InfoContainer';
//import { useGetInfo } from '@ionic/react-hooks/device'; // useGetLanguageCode, availableFeatures
import './Tab1.css';

const Tab1: React.FC = () => {
  //const { info } = useGetInfo();
  //const uuid = JSON.stringify(info)
  //console.log(uuid);
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Tour des Alpages 2021</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tour des Alpages 2021</IonTitle>
          </IonToolbar>
        </IonHeader>
        <InfoContainer name="Infos" />
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
